import './App.css';
import mtn from './mountain.webp';

function App() {
  return (
    <div className="App">
      <img src={mtn} alt="mountain" className='App-logo' />
    </div>
  );
}

export default App;
